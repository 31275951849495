import styled from "styled-components";
import {
  defaultBodyMdRegular,
  defaultBodySmSemibold,
} from "styles/typography/default";


export const Container = styled.div`
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.pad}) {
    width: 960px;
  }
`;

export const Wrapper = styled.div`
  padding: 40px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral10};

  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    padding: 10px;
  }
`;

export const Badges = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`;

export const QRCode = styled.img`
  width: 132px;
  height: 132px;
`;

export const Info = styled.p`
  ${defaultBodySmSemibold}

  color: ${({ theme }) => theme.colors.neutral[500]};

`;
export const InputLink = styled.div`
  ${defaultBodyMdRegular}

  padding: ${({ theme }) => theme.spacing(12)};
  border: 1px solid ${({ theme }) => theme.colors.neutral[300]};
  border-radius: 16px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.neutral10};
  color: ${({ theme }) => theme.colors.neutral[600]};

`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(16)};
  align-items: center;
  justify-content: center;

  :first-child {
    margin-right: ${({ theme }) => theme.spacing(40)};
  }
`;

export const BorderContainer = styled.div`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(32)};
  border-left: 1px solid #bfbcb5;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(24)};
`;

export const LinkContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacing(8, 0, 40)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(8)};
    align-items: center;
    justify-content: center;
  }
`;

export const Image = styled.img``;

export const ImageBadge = styled.img`
`;

export const Link = styled.a`
  display: flex;
  cursor: pointer;
`;

