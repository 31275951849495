import { useTranslation } from "react-i18next";
import useAvoidBackButton from "hooks/useAvoidBackButton";
import { useEffect, useState } from "react";
import { useGiftPacks } from "@ribondao/shared/hooks";
import useQueryParams from "hooks/useQueryParams";
import AppDownloadSection from "./AppDownloadSection";
import logo from "./assets/logo.svg";
import sun from "./assets/sun.svg";
import gift from "./assets/gift.svg";
import * as S from "./styles";

function GiftPage() {
  const { t } = useTranslation("translation", {
    keyPrefix: "giftPage",
  });

  const [sender, setSender] = useState("");
  const queryParams = useQueryParams();

  const giftPackId = queryParams.get("gift_id");

  const { getGiftPack } = useGiftPacks();

  const fetchSender = async () => {
    const giftPack = await getGiftPack(giftPackId);
    if (giftPack?.sender) setSender(giftPack.sender);
  };

  useEffect(() => {
    fetchSender();
  }, [getGiftPack]);

  useAvoidBackButton();

  return (
    <S.Container>
      <S.Background />
      <S.Logo src={logo} />
      <S.Sun src={sun} />
      <S.MainContainer>
        <S.Box>
          <S.Gift src={gift} />
          <S.TitleContainer>
            <S.Sender>{sender}</S.Sender>
            <S.Title>{t("title")}</S.Title>
          </S.TitleContainer>
          <S.Subtitle>{t("subtitle")}</S.Subtitle>
          <S.Steps>
            <S.StepContainer>
              <S.Number>1</S.Number>
              <S.Info>{t("step1")}</S.Info>
            </S.StepContainer>
            <S.StepContainer>
              <S.Number>2</S.Number>
              <S.Info>{t("step2")}</S.Info>
            </S.StepContainer>
            <S.StepContainer>
              <S.Number>3</S.Number>
              <S.Info>{t("step3")}</S.Info>
            </S.StepContainer>
            <S.StepContainer>
              <S.Number>4</S.Number>
              <S.Info>{t("step4")}</S.Info>
            </S.StepContainer>
          </S.Steps>
        </S.Box>
        <AppDownloadSection />
      </S.MainContainer>
    </S.Container>
  );
}

export default GiftPage;
