import styled from "styled-components";
import { defaultBodyMdSemibold, defaultBodySmMedium, defaultHeadingXs } from "styles/typography/default";

export const Container = styled.div`
  padding: 76px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral[25]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    padding: 44px 0px;
    background-color: ${({ theme }) => theme.colors.neutral10};
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.brand.primary[50]};
`;

export const MainContainer = styled.div``;

export const LeftArrow = styled.img`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing(16)};
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 117px;
  margin-bottom: 28px;
  z-index: 1;
`;

export const Sun = styled.img`
  width: 454px;
  z-index: 1;
`;

export const Gift = styled.img`
  width: 320px;
  position: absolute;
  top: -160px;
  left: calc(50% - 160px);
  z-index: 2;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    width: 290px;
    top: -145px;
    left: calc(50% - 145px);
  } 
`;

export const Box = styled.div`
  margin-bottom: 20px;
  padding: 60px 24px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(24)};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral10};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    padding: 40px 16px 0px;
    border-radius: 0;
  }
`;


export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  ${defaultHeadingXs}

  color: ${({ theme }) => theme.colors.brand.primary[600]};
`;

export const Sender = styled.p`
  ${defaultBodyMdSemibold}

  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.neutral[600]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    text-align: center;
  }

`;

export const Subtitle = styled.h2`
  ${defaultBodyMdSemibold}

  color: ${({ theme }) => theme.colors.neutral[600]};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    text-align: center;
  }

`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
  align-items: start;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(8)};
  align-items: center;
`;

export const Number = styled.div`
  ${defaultBodySmMedium}

  width: 20px;
  height: 20px;
  border-radius: 90px;
  display: flex;
  flex-shrink: 0; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brand.primary[100]};
`;

export const Info = styled.div`
  ${defaultBodyMdSemibold}

  color: ${({ theme }) => theme.colors.neutral[600]};
`;