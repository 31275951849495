import { useState } from "react";
import useBreakpoint from "hooks/useBreakpoint";
import { APP_GIFT_LINK } from "utils/constants";
import { useTranslation } from "react-i18next";
import { theme } from "@ribondao/shared/styles";
import Button from "components/atomics/buttons/Button";
import { logEvent } from "lib/events";
import CopyIcon from "assets/icons/copy-icon.svg";
import CheckIcon from "assets/icons/check-copied-icon.svg";
import AppleBadge from "./assets/app-store-badge.svg";
import GoogleBadge from "./assets/google-play-badge.svg";
import QRCode from "./assets/qr-code.svg";
import * as S from "./styles";

function AppDownloadSection(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "giftPage",
  });

  const { isMobile } = useBreakpoint();
  const [isCopy, setIsCopy] = useState(false);

  function handleIosLink() {
    logEvent("appStoreBtn_click", { from: "giftPage" });
    window.open(APP_GIFT_LINK);
  }

  function handleAndroidLink() {
    logEvent("gPlayBtn_click", { from: "giftPage" });
    window.open(APP_GIFT_LINK);
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(APP_GIFT_LINK ?? "");
    setIsCopy(true);
  };

  const render = () => {
    if (isMobile) {
      return (
        <S.ButtonsContainer>
          <S.Link onClick={() => handleAndroidLink()} rel="noopener noreferrer">
            <S.ImageBadge src={GoogleBadge} />
          </S.Link>
          <S.Link
            onClick={() => handleIosLink()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.ImageBadge src={AppleBadge} />
          </S.Link>
        </S.ButtonsContainer>
      );
    } else {
      return (
        <S.Badges>
          <S.ImageContainer>
            <S.Info>{t("qrcode")}</S.Info>
            <S.Image src={QRCode} />
          </S.ImageContainer>
          <S.BorderContainer>
            <S.Info>{t("link")}</S.Info>
            <S.InputLink>{APP_GIFT_LINK}</S.InputLink>
            <Button
              text={isCopy ? t("copySuccess") : t("copy")}
              backgroundColor={
                isCopy
                  ? theme.colors.neutral10
                  : theme.colors.brand.primary[600]
              }
              borderColor={theme.colors.brand.primary[600]}
              textColor={
                isCopy
                  ? theme.colors.brand.primary[600]
                  : theme.colors.neutral10
              }
              leftIcon={isCopy ? CheckIcon : CopyIcon}
              onClick={copyToClipboard}
            />
          </S.BorderContainer>
        </S.Badges>
      );
    }
  };

  return <S.Wrapper>{render()}</S.Wrapper>;
}

export default AppDownloadSection;
